<template>
    <div
        id="modal-webhook"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Detail Webhook</h5>
                </div>
                <div class="modal-body">
                    <table class="table table bordered">
                        <thead class="bg-dark text-white">
                            <tr>
                                <th>
                                    Time
                                </th>
                                <th>Status</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{data.createdDate}}</td>
                                <td>{{data.status}}</td>
                                <td>{{data.response}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row p-3">
                        <h4>Request Body</h4>
                        <textarea class="form-control" rows="10" disabled :value="payload" ></textarea>
                    </div>
                </div>
                <div class="modal-footer">
              <button
                    type="button"
                    class="btn btn-clean btn-bold btn-upper btn-font-md"
                    @click="clearData()"
                >
                    {{ $t("button.close") }}
                </button>
        </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default {

    data() {
        return {
            data: {},
            payload: ''
        };
    },
    mounted() {
        $("#modal-webhook").modal();
        this.data = structuredClone(this.modalData)
        // this.data = this.modalData
        this.payload = this.data.payload ? this.changeData(this.data.payload) : ''
    },
    props: {
        modalData: {
            type: Object,
            default: () => {}
        },

    },
    watch:{
        modalData(item){
           this.data = structuredClone(item)
           this.payload = this.data.payload ? this.changeData(this.data.payload) : ''
        }
    },
    methods: {
        clearData: function() {
            $("#modal-webhook").modal("hide");
        },
        changeData(item){
            if(item){
                return item.replaceAll(',', ',\n')
            } 
            return 
        }
    },

};
</script>
