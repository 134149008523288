<template>
    <div>
        <load-spinner v-if="isLoadingWebhook"></load-spinner>
        <base-header :base-title="headTitle"></base-header>
        <modal-data :modal-data="data" v-if="data.createdDate"></modal-data>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitleName"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{
                                    name: 'manajement-payment-id',
                                    params: { id: idRoute }
                                }"
                            >
                                <i class="flaticon2-back"></i>
                                {{ $t("button.previous") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="!isLoadingWebhook">
                    <!-- content -->
                     <v-server-table
                        :columns="columns"
                        :options="options"
                        ref="history-webhook"
                        @loaded="onLoaded(0)"
                        @loading="onLoaded(1)"
                    >
             <div slot="Actions" slot-scope="{ row }">
                <button class="btn btn-clean btn-icon btn-outline-info btn-sm" @click="showDetail(row)">
                    <i class="la la-eye"></i>
                </button>
            </div>
          </v-server-table>
                   <!-- <v-client-table
                        :data="historyWebhook"
                        :columns="columns"
                        :options="options"
                        ref="table"
                    >
                        <div slot="Actions" slot-scope="{ row }">
                           <button class="btn btn-clean btn-icon btn-outline-info btn-sm" @click="showDetail(row)">
                                <i class="la la-eye"></i>
                           </button>
                        </div> -->
                        <!-- <div><b>Text:</b> {{props.row}}</div> -->
                        <!-- </div>
                        <div slot="message" slot-scope="{ row }">
                        <span>{{ row | showMessage }}</span>
                        </div>
                        <div slot="show" slot-scope="{ row }">
                        <input :id="`select-${row.templateName}`" type="checkbox" v-model="checkBox[row.templateName]" />
                        </div> -->
                    <!-- </v-client-table> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import LoadSpinner from "./../../../components/_general/LoadSpinner";
import { mapState, mapActions } from "vuex";
import ModalData from './ModalData.vue'
import { RepositoryFactory } from "../../repositories/BaseRepositoryFactory"
const PaymentMethod = RepositoryFactory.get('PaymentMethod')
export default {
    components: {
        BaseTitle,
        BaseHeader,
        LoadSpinner,
        ModalData
    },
    data() {
        const vx = this
        return {
            headTitleName: "History Webhook",
            headTitle: "History Webhook",
            idRoute: "",
            historyWebhook: [],
            data: {},
            client: this.$route.params.id,
            isLoading: false,
            columns: [
                "createdDate",
                "transactionOrderId",
                "endPoint",
                "status",
                "Actions"
            ],
             options: {
        texts: {
          filterBy: "Cari",
        },
        filterable: [
         "createdDate",
        "transactionOrderId",
        "endPoint",
        "status"
        ],
        headings: {
            "createdDate": "Time",
            "transactionOrderId": "Order Id",
            "endPoint": "end point"
        },
        stickyHeader: true,
        dateColumns: ["createdDate"],
        debounce: 500,
        filterByColumn: true,
        toMomentFormat: "YYYY-MM-DD",
        listColumns: {
            status:[
                {
                    id: 'SUCCESS',
                    text: 'Success'
                },
                {
                    id: 'FAILED',
                    text: 'Failed'
                }
            ]
        },
        requestFunction(data) {
          let page = {};
          page.sort = "createdDate,desc";
          page.page = data.page - 1;
          page.size = data.limit;
        //   this.isLoaded = false;
           if(data.orderBy) {
              let orderBy = data.orderBy
              page.sort = `${orderBy},${
              data.ascending == "1" ? "desc" : "asc"
            }`;
          }
          const query = {
              ...page,
              'userId.equals': vx.client
          }
          if (_.isEmpty(data.query)) {
            return PaymentMethod.fetchWebhookList(query).catch(function (e) {
              // this.dispatch('error', e);
              toastr.error("Error Get Data");
            });
          } else {
            if(data.query.status){
                query['status.in'] = data.query.status
            }
            if(data.query.endPoint){
                query['endPoint.contains'] = data.query.endPoint
            }
            if(data.query.transactionOrderId){
                query['transactionOrderId.contains'] = data.query.transactionOrderId
            }
            if(data.query.createdDate){
                query['createdDate.greaterOrEqualThan'] = `${moment(data.query.createdDate.start).format("YYYY-MM-DD")}T00:00:00Z`
                query['createdDate.lessOrEqualThan'] = `${moment(data.query.createdDate.end).format("YYYY-MM-DD")}T23:59:00Z`
            }
            
            return PaymentMethod.fetchWebhookList(query).catch(
              function (e) {
                 toastr.error("Error Get Data");
                // this.dispatch('error', e);
              }
            );
          }
        },
        responseAdapter: (resp) => {
          return {
            data: resp.data,
            count: resp.headers["x-total-count"]
              ? resp.headers["x-total-count"]
              : resp.headers["Content-Length"]
              ? resp.headers["Content-Length"]
              : resp.data.length,
          };
        },
        rowClassCallback(row) {
          return row.isNewData ? "highlight" : "";
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-up",
          down: "fa-sort-down ",
        },
        filterByColumn: true,
        sortable: [ "createdDate",
                "transactionOrderId",
                "endPoint",
                "status"],
      },
        };
    },
    computed: {
        ...mapState("PaymentStore", {
            isLoadingWebhook: state => state.isLoadingWebhook
        })
       
    },
    async mounted() {
        this.idRoute =
            this.$route.params && this.$route.params.id
                ? this.$route.params.id
                : "";
        // this.headTitleName += ` ${this.$route.params.payment}`;
         this.historyWebhook = [
             {
                 createdDate: '2 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             },
              {
                 createdDate: '3 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             },
              {
                 createdDate: '4 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             },
              {
                 createdDate: '5 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             },
              {
                 createdDate: '6 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             },
              {
                 createdDate: '7 April 2022',
                 transactionOrderId: '602',
                 endPoint: 'https://payment.webhook.com',
                 status: 'complete',
                 response: 'sstttss',
                 payload: '{base: Resi}'
             }
         ]
    },

    methods: {
        ...mapActions({
            // getDetailPayment: "PaymentStore/GET_PAYMENT_DETAIL"
        }),
        showDetail(item){
            console.log(item)
            this.data = item
            $("#modal-webhook").modal();
        },
         onLoaded: function (t) {
            t == 0 ? (this.isLoading = false) : (this.isLoading = true);
        },
    },
    filters: {
      formatDate(date) {
        if(!date) return ''
        return moment(date).format("dddd, DD MMMM YYYY , HH:mm");
      },
    }
};
</script>

<style></style>
